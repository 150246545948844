import React, { useState } from 'react';

import SearchIcon from './SearchIcon';

const ENTER_KEY = 13;
const SEARCH_BUTTON_EDGE = 35;

const searchFieldStyle = {
  border: '1px #ddd solid',
  display: 'inline-flex',
  justifyContent: 'space-between',
  height: SEARCH_BUTTON_EDGE,
};

const searchFieldButtonStyle = {
  height: SEARCH_BUTTON_EDGE - 2, // reduces 2px because of top and bottom border
  width: SEARCH_BUTTON_EDGE - 2,
  outline: 'none',
  backgroundColor: 'white',
  cursor: 'pointer',
  padding: 5,
  boxSizing: 'border-box',
  appearance: 'none',
  border: 'none',
  borderLeft: '1px #ddd solid',
};

const searchFieldInputStyle = {
  outline: 'none',
  border: 'none',
  fontSize: 14,
  padding: 10,
  flex: 1,
  color: '#5a5a5a',
  fontWeight: 100,
  height: SEARCH_BUTTON_EDGE - 2,
};

const SearchField = ({
  searchText,
  placeholder,
  className,
  onChange,
  onEnter,
  onSearchClick: onSearchClickProp,
}) => {
  const [value, setValue] = useState(searchText || '');

  const onChangeBound = event => {
    setValue(event.target.value);
    if (typeof onChange === 'function') onChange(event.target.value, event);
  };

  const onEnterBound = event => {
    const isEnterPressed =
      event.which === ENTER_KEY || event.keyCode === ENTER_KEY;
    if (isEnterPressed && typeof onEnter === 'function')
      onEnter(event.target.value, event);
  };

  const onSearchClick = () => {
    if (typeof onSearchClickProp === 'function') onSearchClickProp(value);
  };

  return (
    <div className={className} style={searchFieldStyle}>
      <input
        className="react-search-field-input"
        style={searchFieldInputStyle}
        onChange={onChangeBound}
        onKeyPress={onEnterBound}
        placeholder={placeholder}
        type="text"
        value={value}
      />

      <button
        className="react-search-field-button"
        type="button"
        style={searchFieldButtonStyle}
        onClick={onSearchClick}
      >
        <SearchIcon searchButtonEdge={SEARCH_BUTTON_EDGE} />
      </button>
    </div>
  );
};

export default SearchField;
