import React from 'react';
import Pagination from 'react-js-pagination';

import Loader from 'react-loader-spinner';

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      orders: [],
      orderCount: 0,
      activePage: 1,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getOrders(1, 10);
  }

  getOrders(Page, Size) {
    fetch(`/api/order?Page=${Page - 1}&Size=${Size}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
    })
      .then(result => result.json())
      .then(result =>
        this.setState({ orders: result.orders, orderCount: result.count })
      )
      .then(() => this.setState({ isLoading: false }))
      .catch(error => {
        this.setState({ error });
      });
  }

  handlePageChange = pageNumber => {
    this.getOrders(pageNumber, 10);
    this.setState({ activePage: pageNumber });
  };

  unixTimeToDate(unix) {
    const date = new Date(unix * 1000);
    return `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(
      -2
    )}-${date.getFullYear()}`;
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h4>Error loading page.</h4>
          <p>{this.state.error.name}</p>
        </div>
      );
    }
    if (this.state.isLoading) {
      return (
        <center>
          <Loader type="ThreeDots" color="#8b8f65" height="50" width="50" />
        </center>
      );
    }
    return (
      <div className="container">
        <div className="row justify-content-md-center">
          <ul className="list-group">
            {this.state.orders ? (
              this.state.orders.map(order => (
                <li className="list-group-item" key={order.id} style={{ border: "none" }}>
                  <div key={order.id}>
                    <table width="600px">
                      <tbody>
                        <tr>
                          <td width="40%">ID</td>
                          <td>{order.id}</td>
                        </tr>
                        <tr>
                          <td width="40%">Aankoopdatum</td>
                          <td>{this.unixTimeToDate(order.purchaseDate)}</td>
                        </tr>
                        <tr>
                          <td>Verloopdatum</td>
                          <td>{this.unixTimeToDate(order.expirationDate)}</td>
                        </tr>
                        <tr>
                          <td>Herinnering verstuurd</td>
                          <td>{order.reminderSent ? 'Ja' : 'Nee'}</td>
                        </tr>
                        <tr>
                          <td>Verloop verstuurd</td>
                          <td>{order.expirationSent ? 'Ja' : 'Nee'}</td>
                        </tr>
                        <tr>
                          <td>Aantal spots</td>
                          <td>{order.spots && order.spots.length}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              ))
            ) : (
              <li className="list-group-item">Geen Orders.</li>
            )}
          </ul>
        </div>
        <div className="row justify-content-md-center">
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.orderCount}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    );
  }
}

export default OrderList;
