import React from 'react';
import { Field, ErrorMessage } from 'formik';

const AccountInfo = () => (
  <>
    <div className="form-row">
      <div className="col">
        <div className="form-group">
          <label htmlFor="username">E-mailadres</label>
          <Field
            type="text"
            id="username"
            name="username"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="username"
            component="div"
          />
        </div>
      </div>
      <div className="col">
        <div className="form-group">
          <label htmlFor="password">Wachtwoord</label>
          <Field
            type="password"
            id="password"
            name="password"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="password"
            component="div"
          />
        </div>
      </div>
    </div>
  </>
);

export default AccountInfo;
