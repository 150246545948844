import React from 'react';
import { Field, ErrorMessage } from 'formik';

const PersonalInfo = () => (
  <>
    <div className="form-row">
      <div className="col">
        <div className="form-group">
          <label htmlFor="firstName">Voornaam</label>
          <Field
            type="text"
            id="firstName"
            name="firstName"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="firstName"
            component="div"
          />
        </div>
      </div>
      <div className="col">
        <div className="form-group">
          <label htmlFor="lastName">Achternaam</label>
          <Field
            type="text"
            id="lastName"
            name="lastName"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="lastName"
            component="div"
          />
        </div>
      </div>
    </div>

    <div className="form-row">
      <div className="col">
        <div className="form-group">
          <label htmlFor="phoneNumber">Telefoonnummer</label>
          <Field
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="phoneNumber"
            component="div"
          />
        </div>
      </div>

      <div className="col">
        <div className="form-group">
          <label htmlFor="email">Emailadres</label>
          <Field type="text" id="email" name="email" className="form-control" />
          <ErrorMessage
            className="alert alert-danger"
            name="email"
            component="div"
          />
        </div>
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="birthdate">Geboortedatum</label>
      <Field
        type="text"
        placeholder="dd/mm/jjjj"
        id="birthdate"
        name="birthdate"
        className="form-control"
      />
      <ErrorMessage
        className="alert alert-danger"
        name="birthdate"
        component="div"
      />
    </div>
  </>
);

export default PersonalInfo;
