import { persistOrder, reserveSpots } from '../../utils/PersistencyMethods';

const reserveAndOrderSpots = async (timeframe, receiverId = null) => {
  // Reserve the spots that are given to the receiver
  const plotIds = JSON.parse(localStorage.getItem('plotIds'));

  // Configure an order to be persisted based on the receiver's spots
  return reserveSpots(plotIds).then(() => persistOrder(plotIds, timeframe, receiverId));
};

export default reserveAndOrderSpots;