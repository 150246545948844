var PAINT_OPEN_SPOT = {
    'fill-outline-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#000',
      '#AAFFAA',
    ],
    'fill-opacity': 0.6,
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'click'], false],
      '#0000FF',
      '#11AA11',
    ],
  };

var PAINT_RESERVED_SPOT = {
    'fill-color': '#FFA500',
    'fill-opacity': 1,
};

var PAINT_PAYED_SPOT = {
    'fill-color': '#F00',
    'fill-opacity': 0.6,
};

var PAINT_CLOSED_SPOT = {
    'fill-outline-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#000',
      '#AAFFAA',
    ],
    'fill-opacity': 0.6,
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'click'], false],
      '#00F',
      '#F0F',
    ],
  };

export {PAINT_OPEN_SPOT, PAINT_RESERVED_SPOT, PAINT_PAYED_SPOT, PAINT_CLOSED_SPOT}