import React from 'react';
import { Field, ErrorMessage } from 'formik';

const LocationInfo = () => (
  <>
    <div className="form-row">
      <div className="col">
        <div className="form-group">
          <label htmlFor="street">Straatnaam</label>
          <Field
            type="text"
            id="street"
            name="street"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="street"
            component="div"
          />
        </div>
      </div>
      <div className="col">
        <div className="form-group">
          <label htmlFor="number">Huisnummer</label>
          <Field
            type="number"
            id="number"
            name="number"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="number"
            component="div"
          />
        </div>
      </div>
      <div className="col">
        <div className="form-group">
          <label htmlFor="addition">Toevoeging (optioneel)</label>
          <Field
            type="text"
            id="addition"
            name="addition"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="addition"
            component="div"
          />
        </div>
      </div>
    </div>

    <div className="form-row">
      <div className="col">
        <div className="form-group">
          <label htmlFor="postalCode">Postcode</label>
          <Field
            type="text"
            id="postalCode"
            name="postalCode"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="postalCode"
            component="div"
          />
        </div>
      </div>
      <div className="col">
        <div className="form-group">
          <label htmlFor="residency">Woonplaats</label>
          <Field
            type="text"
            id="residency"
            name="residency"
            className="form-control"
          />
          <ErrorMessage
            className="alert alert-danger"
            name="residency"
            component="div"
          />
        </div>
      </div>
    </div>
  </>
);

export default LocationInfo;
