import React, { useState, useEffect } from 'react';
import { DetermineSpotPrice } from '../../constants/PriceService';

const PlotTable = ({ plotIds, timeframe }) => {
  const [plots, setPlots] = useState([]);

  useEffect(() => {
    setPlots(plotIds);
  }, [plotIds]);

  return (
    <>
      {timeframe === '3 jaar' && (
        <div className="alert alert-success">
          Met uw adoptie voor {timeframe} bespaart u in totaal €
          10!
        </div>
      )}
      {timeframe === '5 jaar' && (
        <div className="alert alert-success">
          Met uw adoptie voor {timeframe} bespaart u in totaal €
          25!
        </div>
      )}
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th scope="col">Adoptietijd</th>
            <th scope="col">Hoeveelheid spots</th>
            <th scope="col">Prijs per spot</th>
            <th scope="col">Totaalprijs (incl. BTW)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{timeframe}</td>
            <td>{plots.length}</td>
            <td>€{DetermineSpotPrice(timeframe)}</td>
            <td>€{plots.length * (DetermineSpotPrice(timeframe))}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PlotTable;
