import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthenticationContext } from '../../utils/AuthenticationContext';

const AuthenticatedRoute = ({ component: Component, exact, path }) => {
  const { isAuthenticated } = useContext(AuthenticationContext);

  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default AuthenticatedRoute;
