import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

// Yup specific imports
import initialValues from '../components/UserInformation/YupInitialValues';
import AccountInfoSchema from '../components/UserInformation/ValidationSchema/AccountInformation';
import NewAccountSchema from '../components/UserInformation/ValidationSchema/NewAccount';
import PresentSchema from '../components/UserInformation/ValidationSchema/Present';

import { createPayment } from '../utils/createPayment';
import { authenticate, getJWTToken } from '../utils/jwt';
import mergeYupSchemas from '../utils/MergeYupSchemas';
import {
  persistAccount,
  prepareAccountObject,
} from '../utils/PersistencyMethods';
import generateRandomPassword from '../utils/RandomPassword';

import Layout from '../components/layout';
import {
  AccountInfo,
  LocationInfo,
  PersonalInfo,
  PresentInfo,
} from '../components/UserInformation';
import reserveAndOrderSpots from '../components/UserInformation/ReserveAndOrderSpots';

const UserInformation = ({
  location: {
    state: { timeframe },
  },
}) => {
  const [isPresent, setPresent] = useState(false);
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [error, setError] = useState();

  // Default schema that always needs to be validated (containing username and password)
  let ValidationSchema = AccountInfoSchema;
  if (isNewAccount)
    ValidationSchema = mergeYupSchemas(ValidationSchema, NewAccountSchema);
  if (isPresent)
    ValidationSchema = mergeYupSchemas(ValidationSchema, PresentSchema);

  const onFormSubmit = async (values, setSubmitting) => {
    let accountPromise = null;

    if (isNewAccount) {
      // Create a new account, log it in and add the JWT to localStorage
      const account = prepareAccountObject(values);
      accountPromise = persistAccount(account).then(() =>
        authenticate(account.username, account.password)
      );
    } else {
      // Validate the account to log in with and add the JWT to localStorage
      accountPromise = authenticate(values.username, values.password);
    }

    // Create a receiver account with automatically generated password
    if (isPresent) {
      // Check if the receiver account already exists
      const receiverId = await fetch(
        `/api/account/email/${values.receiverEmail}`
      ).then(response => {
        if (response.status === 204) return null;
        return response.json();
      });

      if (receiverId != null) {
        reserveAndOrderSpots(timeframe, receiverId)
          .then(orderId => createPayment(orderId, values.receiverEmail))
          .catch(error => {
            error
              .text()
              .then(message => {
                setError(message);
                setSubmitting(false);
              })
              .catch(() => {
                setError(error);
                setSubmitting(false);
              });
          });
      } else {
        const randomPassword = generateRandomPassword();

        // Replace username, password and email of person that gifts the spot with the info of the receiver
        // This way the receiver will get the reminder after the spot adoption runs out
        const receiverValues = Object.assign({}, values);
        receiverValues.username = values.receiverEmail;
        receiverValues.password = randomPassword;
        receiverValues.email = values.receiverEmail;

        // Save the receiver account
        const receiverAccount = prepareAccountObject(receiverValues, true);
        const spotReceiverPromise = persistAccount(receiverAccount);

        let orderId;

        // Wait for the accountPromise and spot receiver Promise to both succeed
        Promise.all([accountPromise, spotReceiverPromise])
          // Log in with the created receiver account
          .then(() => authenticate(values.receiverEmail, randomPassword))
          // Call convenience method (avoiding duplicate code) to reserve and order the specified spots
          .then(() => reserveAndOrderSpots(timeframe))
          // Log back in with the account that gives the plot(s)
          .then(result => (orderId = result))
          .then(() => authenticate(values.username, values.password))
          // Create the payment in order to pay for the reserverd spots
          .then(() => createPayment(orderId, values.receiverEmail))
          .catch(error => {
            error
              .text()
              .then(message => {
                setError(message);
                setSubmitting(false);
              })
              .catch(() => {
                setError(error);
                setSubmitting(false);
              });
          });
      }
      // Make sure the 'not a gift' section isn't run after this
      return;
    }

    // Not a gift
    accountPromise
      // Call convenience method (avoiding duplicate code) to reserve and order the specified spots
      .then(() => reserveAndOrderSpots(timeframe))

      // TODO: clean up again, ideally the whole application gets rewritten
      .then(res => {
        const username = localStorage.getItem('username');
        fetch(`api/account/username/${username}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getJWTToken()}`,
          },
        })
          .then(response => response.json())
          .then(account => createPayment(res, account.email));
      })
      .catch(error => {
        error
          .text()
          .then(message => {
            if (error.status !== 401) setError(message);
            else setError('E-mailadres/wachtwoord klopt niet');
            setSubmitting(false);
          })
          .catch(() => {
            setError(error);
            setSubmitting(false);
          });
      });
  };

  return (
    <Layout>
      <section className="checkout-page-section">
        <div className="container">
          <div className="cps-wrap">
            <div className="form">
              <h1>Jouw gegevens</h1>
              <p>Vul hieronder je gegevens in om de adoptie van de door jou gekozen spot(s) af te ronden.</p>
              <Formik
                validationSchema={ValidationSchema}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) =>
                  onFormSubmit(values, setSubmitting)
                }
                render={({ isSubmitting }) => (
                  <Form>
                    <AccountInfo />

                    <div className="custom-checkbox">
                      <Field
                        type="checkbox"
                        id="forNewAccount"
                        name="forNewAccount"
                        onChange={event => setIsNewAccount(event.target.checked)}
                      />
                      <label htmlFor="forNewAccount">Ik heb nog geen account</label>
                      <ErrorMessage
                        className="alert alert-danger"
                        name="forNewAccount"
                        component="div"
                      />
                    </div>

                    {isNewAccount && (
                      <>
                        <PersonalInfo />
                        <LocationInfo />
                      </>
                    )}

                    <div className="custom-checkbox">
                      <Field
                        type="checkbox"
                        id="forPresent"
                        name="forPresent"
                        onChange={event => setPresent(event.target.checked)}
                      />
                      <label htmlFor="forPresent">Cadeau doen</label>
                      <ErrorMessage
                        className="alert alert-danger"
                        name="forPresent"
                        component="div"
                      />
                    </div>

                    {isPresent && <PresentInfo />}

                    <button
                      type="submit"
                      className="button"
                      disabled={isSubmitting}
                    >
                      Naar betalen
            </button>
                  </Form>
                )}
              />

              {error && (
                <div className="alert alert-danger mt-3" role="alert">
                  {error.status ? (
                    <>
                      Er is helaas een error opgetreden met de code {error.status}.{' '}
                      <br />
              Neem contact op met de beheerder en/of probeer het later nog eens.
                    </>
                  ) : (
                      <>{error}</>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default UserInformation;