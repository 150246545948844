const DetermineSpotPrice = (timeframe) => {
    switch (timeframe) {
        case '3 jaar':
          // 3 years costs €60 instead of €75, which saves €5 per year
          return '65';
        case '5 jaar':
          // 5 years costs €90 instead of €125, which saves €7 per year
          return '100';
        default:
          return '25';
      }
}

export { DetermineSpotPrice };