import React from 'react';

import mapboxgl from 'mapbox-gl';
import { createPayment } from '../../utils/createPayment';

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.getSpotsByAccountID = this.getSpotsByAccountID.bind(this);
    this.unixTimeToDate = this.unixTimeToDate.bind(this);
    this.integerToSpotStatus = this.integerToSpotStatus.bind(this);
    this.calctotalprice = this.calctotalprice.bind(this);

    this.state = {
      error: '',
      orders: [],
    };
  }

  async componentDidMount() {
    await this.getSpotsByAccountID(localStorage.getItem('accountID'));
    for (const order of this.state.orders) {
      mapboxgl.accessToken =
        'pk.eyJ1Ijoic3Bvb3JwYXJrdGlsYnVyZyIsImEiOiJjandqNTB1Y2owZ243NDNwYnQ2eWJuNWp0In0.k5Q6UPxmFmxe6fw7upbdXg';
      if (process.env.NODE_ENV === 'production') {
        mapboxgl.accessToken =
          'pk.eyJ1IjoibXZnaGVhYmIiLCJhIjoiY2tsOW54MjBwM2QyZzMwbXN6ZzI0dWloMyJ9.eH2SwEQ8vP84l6mJeC7ivw';
      }

      const map = new mapboxgl.Map({
        container: `map${order.id}`,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [5.0697, 51.5616],
        minZoom: 16,
        maxZoom: 17.6,
        zoom: 16,
      });
      map.on('load', function () {

        var layers = map.getStyle().layers;
        // Find the index of the first symbol layer in the map style
        var firstSymbolId;
        for (var i = 0; i < layers.length; i++) {
          if (layers[i].type === 'symbol') {
            firstSymbolId = layers[i].id;
            break;
          }
        }

        for (const spot of order.spots) {
          fetch(`/api/spot/geojson/${spot.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
            },
          })
            .then(result => result.json())
            .then(spots => {
              map.addSource(`spots${spot.id}`, {
                type: 'geojson',
                data: spots[0],
              });

              map.addLayer(
                {
                  id: `state-fills${spot.id}`,
                  type: 'fill',
                  source: `spots${spot.id}`,
                  layout: {},
                  paint: {
                    'fill-color': '#F00',
                    'fill-opacity': 0.6,
                  },
                },
              );
              map.flyTo({
                center: [
                  spots[0].features[0].geometry.coordinates[0][0][0],
                  spots[0].features[0].geometry.coordinates[0][0][1],
                ],
                zoom: 16.5,
                pitch: 0,
                bearing: 0,
              });
            })
            .catch(error => {
              this.setState(error);
            });
        }

        map.addLayer(
          {
            id: 'spoorpark',
            source: {
              type: 'raster',
              url: 'mapbox://spoorparktilburg.bioumigo',
            },
            type: 'raster',
          },
          firstSymbolId
        );
      });

      //setTimeout(() => {

      //}, 2000);
    }
  }

  async getSpotsByAccountID(accountID) {
    await fetch(`/api/order/accountID/${accountID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
    })
      .then(res => res.json())
      .then(res => {
        this.setState({ orders: res.orders });
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  async generateCertificates(order) {

    await fetch(`/api/pdf/certificate/${localStorage.getItem('accountID')}/${order.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
    })
      .then(res => {
        debugger;
        return res.blob()
      })
      .then(blob => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], { type: "application/zip" })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = order.spots.length > 1 ? "certificaten.zip" : "certificaat.zip";
        link.click();
      })
      .catch(error => {
        this.setState({ error });
        console.log(error)
        return null;
      });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  unixTimeToDate(unix) {
    const date = new Date(unix * 1000);
    return `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(
      -2
    )}-${date.getFullYear()}`;
  }

  integerToSpotStatus(spotstatus) {
    switch (spotstatus) {
      case 0:
        return 'Open';
      case 1:
        return 'Gereserveerd';
      case 2:
        return 'Betaald';
      case 3:
        return 'Gesloten';
      default:
        return 'Open';
    }
  }

  calctotalprice(spots) {
    var totalprice = 0;

    for (var i = 0; i < spots.length; i++) {
      totalprice += spots[i].price;
    }
    return totalprice;
}

render() {
  if (
    localStorage.getItem('jwt-token') != null &&
    this.state.orders.length > 0
  ) {
    return (
      <div>
        {this.state.orders.map(order => {
          const mapID = `map${order.id}`;
          return (
            <div key={order.id}>
              <h2 className="unwidth">
                Order: {this.unixTimeToDate(order.purchaseDate)}
              </h2>

              <div
                id={mapID}
                style={{ height: '450px', width: '100%' }}
              />

                    Plekken behorende tot deze order worden weergegeven
                    door een{' '}
              <div
                style={{
                  height: '10px',
                  width: '10px',
                  backgroundColor: 'red',
                  display: 'inline-block',
                }}
              />

              <h3 className="unwidth">
                Spot(s)
                  </h3>
              {order.spots.map((spot, i) => (
                <table
                  width="100%"
                  key={spot.id}
                  style={{ marginBottom: '30px' }}
                >
                  <tbody>
                    <tr>
                      <td rowSpan="2">Spot {i + 1}</td>
                      <td>
                        lengtegraad: {spot.coordinates[0].lng}{' '}
                        <br />
                          breedtegraad: {spot.coordinates[0].lat}{' '}
                        <br />
                          Bedrag: €{spot.price}{' '},-
                        </td>
                    </tr>
                  </tbody>
                </table>
              ))}

              <h3 className="unwidth">
                Order informatie
                  </h3>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="30%">Order ID</td>
                    <td>{order.id}</td>
                  </tr>
                  <tr>
                    <td width="30%">Aankoopdatum</td>
                    <td>{this.unixTimeToDate(order.purchaseDate)}</td>
                  </tr>
                  <tr>
                    <td>Verloopdatum</td>
                    <td>{this.unixTimeToDate(order.expirationDate)}</td>
                  </tr>
                  <tr>
                    <td>Herinnering verstuurd</td>
                    <td>{order.reminderSent ? 'Ja' : 'Nee'}</td>
                  </tr>
                  <tr>
                    <td>Verloop verstuurd</td>
                    <td>{order.expirationSent ? 'Ja' : 'Nee'}</td>
                  </tr>
                  <tr>
                    <td>Totaalbedrag</td>
                    <td>€{this.calctotalprice(order.spots)},-</td>
                  </tr>
                </tbody>
              </table>

              <p className="unwidth">
                {order.expirationSent ? '' :
                  <button
                    type="button"
                    className="button active"
                    onClick={() => createPayment(order.id, '')}
                  >
                    Verleng Order met 1 jaar (€{this.calctotalprice(order.spots)},-)
                  </button>
                }
                <button
                  type="button"
                  className="button active"
                  onClick={() => this.generateCertificates(order)}
                >
                  {order.spots.length > 1 ? "Download certificaten" : "Download certificaat"}
                </button>

              </p>

              <hr />

            </div>
          );
        })}
        {this.state.error && (
          <div className="alert alert-danger mt-3" role="alert">
            Er is helaas een error opgetreden met de code {this.state.error}.{' '}
            <br />
              Neem contact op met de beheerder en/of probeer het later nog eens.
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      Laden <br /> <br />
    </div>
  );
}
}

export default OrderDetails;
