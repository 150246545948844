import React, { useContext, useState } from 'react';

import jwt_decode from 'jwt-decode';

import { AuthenticationContext } from '../../utils/AuthenticationContext';
import { addJWTToken } from '../../utils/jwt';

const LoginForm = ({ history }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const { setAuthenticated } = useContext(AuthenticationContext);

  const handleSubmit = event => {
    event.preventDefault();

    fetch('/api/authentication', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then(response => {
        if (response.ok) return response.text();
        throw response;
      })
      .then(token => addJWTToken(token))
      .then(() => setAuthenticated(true))
      .then(() => {
        const decoded = jwt_decode(localStorage.getItem('jwt-token'));
        localStorage.setItem('username', decoded.username);
        localStorage.setItem('accountID', decoded.accountID);
      })
      .then(() => history.push('/gebruiker'))
      .catch(error => {
        setError(error);
      });
  };

  const showErrorMessage = error => (
    <div className="alert alert-danger">
      {error.status === 401
        ? 'Incorrecte inloggegevens'
        : `Een error met de code ${error.status} is opgetreden.
          Neem contact op met de administrator en/of probeer het opnieuw.`}
    </div>
  );

  return (
    <>
      {error && showErrorMessage(error)}
      <form autoComplete="off" style={{ width: '300px' }}>
        <label htmlFor="username">E-mailadres</label>
          <input
            type="text"
            id="username"
            value={username}
            placeholder="E-mailadres"
            onChange={event => setUsername(event.target.value)}
        />
        <label htmlFor="password">Wachtwoord</label>
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Wachtwoord"
            onChange={event => setPassword(event.target.value)}
          />
        <button
          type="submit"
          className="button"
          onClick={handleSubmit}
        >
          Login
        </button>
      </form>
    </>
  );
};

export default LoginForm;
