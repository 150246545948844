import JwtDecode from 'jwt-decode';

export const getJWTToken = () => localStorage.getItem('jwt-token');

export const addJWTToken = token => localStorage.setItem('jwt-token', token);

export const removeJWTToken = () => localStorage.removeItem('jwt-token');

export const authenticate = (username, password) =>
  new Promise((resolve, reject) =>
    fetch('/api/authentication', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then(response => {
        if (!response.ok) throw response;
        return response.text();
      })
      .then(token => {
        addJWTToken(token);
        const decodedToken = JwtDecode(token);

        localStorage.setItem('username', decodedToken.username);
        localStorage.setItem('accountID', decodedToken.accountID);

        resolve(token);
      })
      .catch(error => reject(error))
  );
