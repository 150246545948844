import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { AuthenticationProvider } from './utils/AuthenticationContext';
import { getJWTToken } from './utils/jwt';
import ScrollToTop from './utils/ScrollToTop';

import Home from './pages/Home';
import Cart from './pages/Cart';
import PlotPicker from './pages/PlotPicker';
import UserInformation from './pages/UserInformation';
import UserLogin from './pages/UserLogin';
import ResetPassword from './pages/ResetPassword';
import ReceiverpasswordReset from './pages/ReceiverPasswordReset';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import { UserOrder, UserSettings, User } from './pages/User.js';
import PaymentResult from './pages/PaymentResultV2';
import {
  Admin,
  AdminAccount,
  AdminSpot,
  AdminOrder,
  AdminLogin,
} from './pages/Admin.js';

const App = () => {
  const [isAuthenticated, setAuthenticated] = useState(getJWTToken() != null);
  return (
    <AuthenticationProvider isAuthenticated={isAuthenticated} setAuthenticated={setAuthenticated}>
      <Router>
        <ScrollToTop>

          <Route exact path="/" component={Home} />
          <Route exact path="/plotpicker" component={PlotPicker} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/user-information" component={UserInformation} />
          <Route exact path="/login" component={UserLogin} />
          <Route exact path="/password-reset/:code" component={ResetPassword} />
          <Route exact path="/payment-result" component={PaymentResult} />
          <Route exact path="/receiver-password-reset" component={ReceiverpasswordReset} />
          <AuthenticatedRoute exact path="/gebruiker" component={User} />
          <AuthenticatedRoute exact path="/gebruiker/orderoverzicht" component={UserOrder} />
          <AuthenticatedRoute exact path="/gebruiker/instellingen" component={UserSettings} />       

          <Route exact path="/admin" component={AdminLogin} />
          <AuthenticatedRoute exact path="/admin/overzicht" component={Admin} />
          <AuthenticatedRoute exact path="/admin/overzicht/account" component={AdminAccount} />
          <AuthenticatedRoute exact path="/admin/overzicht/plek" component={AdminSpot} />
          <AuthenticatedRoute exact path="/admin/overzicht/order" component={AdminOrder} />

        </ScrollToTop>
      </Router>
    </AuthenticationProvider>
  );
};

export default App;

