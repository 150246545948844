import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../images/spoorpark-logo-in-groen-blokje-met-schaduw.png';
import { AuthenticationContext } from '../../../utils/AuthenticationContext';
import { removeJWTToken } from '../../../utils/jwt';
//import './styles.css';

const Navigation = () => {

  const { isAuthenticated, setAuthenticated } = useContext(
    AuthenticationContext
  );

  const logOut = () => {
    removeJWTToken();
    setAuthenticated(false);
    localStorage.removeItem('username');
    localStorage.removeItem('accountID');
    localStorage.removeItem('orderID');
    localStorage.removeItem('paymentID');
    localStorage.removeItem('plotIds');
  };

  return (
    <div className="header-vrienden-spoorpark">
      <div className="hvs-wrap">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo"></img>
          </Link>
        </div>
        <div className="hvs-balk">
          <div className="content">
            <ul>
              {isAuthenticated ? (
                <>
                  <li>
                    <Link to="/gebruiker">
                      Mijn account
                    </Link>
                  </li>
                  <li>
                    <Link onClick={logOut} to="/">
                      Log uit
                    </Link>
                  </li>
                </>
              ) : (
                  <li>
                    <Link to="/login">
                      Log in
                    </Link>
                  </li>
                )}
            </ul>
          </div>
        </div>
      </div>
    </div>

    //<li>
    //  <a href="/">
    //    Home
    //                    </a>
    //</li>

    //<div className="nav-container">
    //  <div className="container d-flex justify-content-between align-items-center flex-sm-row flex-wrap">
    //    <Link to="/">
    //      <img className="logo" src={Logo} alt="Spoorpark logo" />
    //    </Link>

    //    <div className="d-flex">
    //      {!true ? (
    //        <>
    //          <Link to="/gebruiker" className="d-flex first-navigation-item">
    //            <p className="navigation-item-text">Gebruikerspaneel</p>
    //          </Link>
    //          <Link className="d-flex" to="/">
    //            <p className="navigation-item-text">Log uit</p>
    //            />
    //          </Link>
    //        </>
    //      ) : (
    //          <Link to="/login" className="d-flex">
    //            <p className="navigation-item-text">Log in</p>
    //          </Link>
    //        )}
    //    </div>
    //  </div>
    //</div>
  );
};

export default Navigation;
