import React from 'react';

import './styles.css';
import mapboxgl from 'mapbox-gl';
import Loader from 'react-loader-spinner';
import { PAINT_CLOSED_SPOT, PAINT_OPEN_SPOT, PAINT_PAYED_SPOT, PAINT_RESERVED_SPOT } from '../../constants/Paints';

class PlotPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlots: [],
      clickStateIds: new Map(),
      hoverStateId: null,
      error: '',
      isLoading: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.clickSpot = this.clickSpot.bind(this);
  }

  componentDidMount() {
    mapboxgl.accessToken =
      'pk.eyJ1Ijoic3Bvb3JwYXJrdGlsYnVyZyIsImEiOiJjandqNTB1Y2owZ243NDNwYnQ2eWJuNWp0In0.k5Q6UPxmFmxe6fw7upbdXg';
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [5.0697, 51.5616],
      minZoom: 16,
      maxZoom: 16.9,
      zoom: 16,
    });

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', function () {

      var layers = map.getStyle().layers;
      // Find the index of the first symbol layer in the map style
      var firstSymbolId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol') {
          firstSymbolId = layers[i].id;
          break;
        }
      }

      map.addLayer({
        id: 'spoorpark',
        source: {
          type: 'raster',
          url: 'mapbox://spoorparktilburg.bioumigo',
        },
        type: 'raster',
      },
        firstSymbolId);
    })

    // use props here for fetch call Tom.
    fetch(this.props.spotapi, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt-token')}`,
      },
    })
      .then(result => result.json())

      //.then(
        
      //)

      .then(spots => {
        // TODO: cache this!!
        map.addSource('openSpots', {
          type: 'geojson',
          data: spots[0],
        });

        map.addLayer({
          id: 'state-fills',
          type: 'fill',
          source: 'openSpots',
          layout: {},
          paint: PAINT_OPEN_SPOT
        });
        this.setState({ isLoading: false })
        map.on('mouseenter', 'state-fills', function () {
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'state-fills', function () {
          map.getCanvas().style.cursor = 'default';
        });

        // Add clicked spot to the selected spots
        map.on('click', 'state-fills', this.handleClick);
        // Show hovered spot
        map.on('mousemove', 'state-fills', e => {
          if (e.features.length > 0) {
            if (this.state.hoverStateId) {
              map.setFeatureState(
                { source: 'openSpots', id: this.state.hoverStateId },
                { hover: false }
              );
            }
            this.setState({ hoverStateId: e.features[0].id });
            map.setFeatureState(
              { source: 'openSpots', id: this.state.hoverStateId },
              { hover: true }
            );
          }
        });

        // Show clicked spot
        map.on('click', 'state-fills', e => {
          if (e.features.length > 0) {
            if (this.state.clickStateIds[e.features[0].id]) {
              map.setFeatureState(
                {
                  source: 'openSpots',
                  id: e.features[0].id,
                },
                {
                  click: false,
                }
              );
              const tempPlots = [...this.state.clickStateIds];
              tempPlots[e.features[0].id] = false;
              this.setState({ clickStateIds: tempPlots });
            } else {
              const tempPlots = [...this.state.clickStateIds, e.features[0].id];
              tempPlots[e.features[0].id] = true;
              this.setState({ clickStateIds: tempPlots });
              map.setFeatureState(
                {
                  source: 'openSpots',
                  id: e.features[0].id,
                },
                { click: true }
              );
            }
          }
        });
        if (spots[1] != null) {
          map.addSource('reservedSpot', {
            type: 'geojson',
            data: spots[1],
          });

          map.addLayer({
            id: 'state-reserved',
            type: 'fill',
            source: 'reservedSpot',
            layout: {},
            paint: PAINT_RESERVED_SPOT
          });
        }

        map.on('click', 'state-reserved', e => {
          this.clickSpot(map, e, 'openSpots');
        });

        if (spots[2] != null) {
          map.addSource('paidSpot', {
            type: 'geojson',
            data: spots[2],
          });

          map.addLayer({
            id: 'state-paid',
            type: 'fill',
            source: 'paidSpot',
            layout: {},
            paint: PAINT_PAYED_SPOT
          });
        }

        if (spots[3] != null) {
          map.addSource('closedSpot', {
            type: 'geojson',
            data: spots[3],
          });

          map.addLayer({
            id: 'state-closed',
            type: 'fill',
            source: 'closedSpot',
            layout: {},
            paint: PAINT_CLOSED_SPOT
          });
          map.on('click', 'state-closed', e => {
            this.clickSpot(map, e, 'closedSpot');
          });
        }
        // Add clicked spot to the selected spots
        if (this.props.spotapi === '/api/spot/geojsonadmin') {
          map.on('click', 'state-fills', this.handleClick);
          map.on('click', 'state-closed', this.handleClick);
        } else {
          map.on('click', 'state-fills', this.handleClick);
        }
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  // Handle click for the spot selecting
  handleClick = e => {
    const { setSelectedPlots } = this.props;
    if (!this.state.clickStateIds[e.features[0].id]) {
      const tempPlots = [...this.state.selectedPlots];

      // Check the spot id if it exists in the array
      for (let i = 0; i < tempPlots.length; i += 1) {
        if (tempPlots[i].id === e.features[0].id) {
          tempPlots.splice(i, 1);
          i--;
        }
      }

      // Set the state and the selected plots again
      this.setState({ selectedPlots: tempPlots });
      setSelectedPlots(tempPlots);
    } else {
      // Add a new spot to the state
      const tempPlots = [...this.state.selectedPlots, e.features[0]];
      this.setState({ selectedPlots: tempPlots });
      setSelectedPlots(tempPlots);
    }
  };

  clickSpot(map, e, source) {
    if (e.features.length > 0) {
      if (this.state.clickStateIds[e.features[0].id]) {
        map.setFeatureState(
          {
            source,
            id: e.features[0].id,
          },
          {
            click: false,
          }
        );
        const tempPlots = [...this.state.clickStateIds];
        tempPlots[e.features[0].id] = false;
        this.setState({ clickStateIds: tempPlots });
      } else {
        const tempPlots = [...this.state.clickStateIds, e.features[0].id];
        tempPlots[e.features[0].id] = true;
        this.setState({ clickStateIds: tempPlots });
        map.setFeatureState(
          {
            source,
            id: e.features[0].id,
          },
          { click: true }
        );
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.error && (
          <div className="alert alert-danger" style={{ marginTop: '20px' }}>
            Kon spots niet ophalen. Probeer het later opnieuw.
          </div>
        )}
        <div style={{ zIndex: 1000, left: '40%', top: '80%', position: 'absolute' , visibility: this.state.isLoading ? 'visible' : 'hidden' }}>
          <center>
            <Loader type="ThreeDots" color="#8b8f65" height="100" width="100" />
          </center>
        </div>
        <div className="map_box_container">
          <div
            id="map"
            className={this.state.isLoading ? 'blur' : 'animated'}
          />
        </div>
      </div>
    );
  }
}

export default PlotPicker;