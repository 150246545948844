import React from 'react';

import Layout from '../components/layout';
import LoginForm from '../components/User/LoginForm';
import ForgottenPassword from '../components/User/ForgottenPassword';

const UserLogin = ({ history }) => (
  <Layout>
    <section className="checkout-page-section">
      <div className="container">
        <div className="cps-wrap">
          <div className="form">
            <h1>Login</h1>
            <LoginForm history={history} />
            <ForgottenPassword />
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default UserLogin;