import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//import './styles.css';

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Het ingevulde e-mailadres is niet valide')
    .required('Email is verplicht'),
});

const ForgottenPassword = ({ receiverVersion }) => {
  const [forgottenPassword, setForgottenPassword] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  return (
    <div className="wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          fetch('/api/account/password-reset', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values.email),
          })
            .then(response => {
              if (response.ok)
                setSuccess('Verwacht een email in uw inbox met instructies (Controleer ook uw spamfolder)');
              else throw response;
            })
            .catch(error => {
              if (error.status === 404)
                setError(
                  'Er bestaat geen account behorend bij het aangegeven e-mailadres'
                );
              else {
                setError(
                  `Er is helaas een fout met statuscode ${
                    error.status
                  } opgetreden. Neem contact op of probeer het later opnieuw.`
                );
              }

              setSubmitting(false);
            });
        }}
        render={({ isSubmitting }) => (
          <Form>
            {!receiverVersion && (
              <div className="custom-checkbox">
                <Field
                  type="checkbox"
                  id="forgottenPassword"
                  name="forgottenPassword"
                  onChange={event => setForgottenPassword(event.target.checked)}
                />
                <label htmlFor="forgottenPassword">Wachtwoord vergeten</label>
                <ErrorMessage
                  className="alert alert-danger"
                  name="forgottenPassword"
                  component="div"
                />
              </div>
            )}

            {(forgottenPassword || receiverVersion) && (
              <>
                <p>
                  Vul hier uw e-mailadres in om een nieuw wachtwoord in te stellen
                </p>

                <div>
                  <label htmlFor="email">Emailadres</label>
                  <Field
                    type="Emailadres"
                    id="email"
                    name="email"
                    className="form-control"
                    style={{ width: '300px' }}
                  />
                  <ErrorMessage
                    className="alert alert-danger"
                    name="email"
                    component="div"
                  />
                </div>

                <button
                  disabled={isSubmitting}
                  className="button"
                  type="submit"
                >
                  Verstuur
                </button>
              </>
            )}
          </Form>
        )}
      />

      {error && <div className="alert alert-danger message">{error}</div>}
      {success && <div className="alert alert-success message">{success}</div>}
    </div>
  );
};

export default ForgottenPassword;
