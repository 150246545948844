import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Layout from '../components/layout';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Nieuw wachtwoord is verplicht'),
  checkPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Wachtwoorden komen niet overeen')
    .required('Wachtwoordconfirmatie is verplicht'),
});

const ResetPassword = ({ match: { params } }) => {
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [code, setCode] = useState();

  useEffect(() => {
    if (params.code) setCode(params.code);
  }, [params.code]);

  return (
    <Layout>
      <section className="checkout-page-section">
        <div className="container">
          <div className="cps-wrap">
            <div className="form">
              {code ? (
                <>
                  <h1>Aanvraag wachtwoordreset</h1>
                  <p>Vul hieronder uw nieuwe wachtwoord in</p>

                  <Formik
                    validationSchema={validationSchema}
                    initialValues={{ password: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                      fetch(`/api/account/password-reset/${code}`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values.password),
                      })
                        .then(response => {
                          if (response.ok)
                            setSuccess('Uw wachtwoord is succesvol gewijzigd');
                          else throw response;
                        })
                        .catch(error => {
                          if (error.status === 404)
                            setError(
                              'Deze link is verlopen. Genereer een nieuwe op de login pagina'
                            );
                          else
                            setError(
                              `Er is helaas een fout met statuscode ${
                              error.status
                              } opgetreden. Neem contact op of probeer het later opnieuw.`
                            );

                          setSubmitting(false);
                        });
                    }}
                    render={({ isSubmitting }) => (
                      <Form>
                        <label htmlFor="password">Nieuw wachtwoord</label>
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          className="form-control"
                        />

                        <ErrorMessage
                          className="alert alert-danger"
                          name="password"
                          component="div"
                        />

                        <label htmlFor="password">Herhaal wachtwoord</label>
                        <Field
                          type="password"
                          id="checkPassword"
                          name="checkPassword"
                          className="form-control"
                        />

                        <ErrorMessage
                          className="alert alert-danger"
                          name="checkPassword"
                          component="div"
                        />

                        <button
                          disabled={isSubmitting}
                          className="button"
                          type="submit"
                        >
                          Wijzig
                </button>
                      </Form>

                    )}
                  />
                  {error && (
                    <div className="alert alert-danger" style={{ marginTop: '15px' }}>
                      {error}
                    </div>
                  )}
                  {success && (
                    <div className="alert alert-success" style={{ marginTop: '15px' }}>
                      {success}
                    </div>
                  )}
                </>
              ) : (
                  <p>
                    Kon resetcode niet uit de link halen. Heeft u de goede link gebruikt?
                  </p>
                )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ResetPassword;
