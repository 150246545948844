import React from 'react';

import Layout from '../components/layout';
import ParkPlan from '../images/parkplan.jpg';
import Hero from '../images/SpoorparkHero.jpg';
import CallToAction from '../components/call-to-action';

const Home = () => (
  <Layout>

    <div className="hero-vrienden-spoorpark">
      <div className="hvs-wrap">
        <div className="background-image">
          <img src={Hero} alt="Hero"></img>
        </div>
        <div className="hvs-title">
          <h1>
            Vrienden van het Spoorpark
            </h1>
        </div>
        <div className="hvs-divider"></div>
      </div>
    </div>

    <section className="section-center-text">
      <div className="container">
        <div className="page-content">
          <h2>
            Het Spoorpark is van ons allemaal!
            </h2>
          <p>
            Meer dan welk ander park in de stad is het Spoorpark van ons allemaal.
            Het is gerealiseerd als burgerinitiatief en die basisgedachte willen we vasthouden.
            Dus hoe meer burgers aanhaken, meedoen, enthousiast zijn, het park omarmen en ons ook financieel steunen, des te meer het Spoorpark een plek zal zijn waar alle Tilburgers zich niet alleen gevoelsmatig, maar ook feitelijk aan kunnen verbinden.
            </p>
          <p>
            <img src={ParkPlan} alt="parkplattegrond" width="800" height="600"></img>
          </p>
          <h2>
            Word vriend van het Spoorpark en adopteer je eigen plek in het park!
            </h2>
          <p>
            Voor 25 euro per jaar per 10 vierkante meter ben je al Vriend van het Spoorpark. 
            Met de steun van al onze vrienden kunnen we onze belofte waarmaken: een schoon, veilig en gezellig park voor jong en oud. 
            Doe mee en kies jouw favoriete spot(s) in het park. Of geef een plek cadeau aan een vriend, vriendin, collega, kind of kleinkind.
            </p>
          <p>
            <CallToAction text="Adopteer spot" route="/plotpicker" />
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default Home;

//<h1>Het Spoorpark is van ons allemaal!</h1>
//  <p>
//    Meer dan welk ander park in de stad is het Spoorpark van ons allemaal.{' '}
//    <br />
//      Het is gerealiseerd als burgerinitiatief en die basisgedachte willen we
//      vasthouden. <br />
//      Dus hoe meer burgers aanhaken, meedoen, enthousiast zijn, het park omarmen
//      en ons ook financieel steunen, des te meer het Spoorpark een plek zal zijn
//      waar alle Tilburgers zich niet alleen gevoelsmatig, maar ook feitelijk aan
//      kunnen verbinden.
//    </p>
//  <img
//    className="img-fluid mx-auto d-block parkplan"
//    src={ParkPlan}
//    alt="parkplattegrond"
//  />
//  <h2>
//    Sluit u aan bij de Vrienden van het Spoorpark door het adopteren van een
//    spot binnen het park!
//    </h2>
//  <p>
//    Voor 25 euro per jaar per 10 vierkante meter bent u al Vriend van het
//      Spoorpark. <br />
//      Met de steun van al onze vrienden kunnen we onze belofte waarmaken: een
//      schoon, veilig en gezellig park voor jong en oud. <br />
//      Doe mee en kies uw favoriete spot(s) in het park. <br />
//      Of geef het cadeau aan een vriend, vriendin, collega, uw kind of
//      kleinkind.
//    </p>
//  <CallToAction large text="Adopteer spot" route="/plotpicker" />