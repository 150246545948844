import React from 'react';

import Layout from '../components/layout';
import ForgottenPassword from '../components/User/ForgottenPassword';

const ReceiverpasswordReset = () => (
  <Layout>
    <h1>Gefeliciteerd met uw geschonken spot!</h1>
    <ForgottenPassword receiverVersion />
  </Layout>
);

export default ReceiverpasswordReset;
