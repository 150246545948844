import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';

import Layout from '../components/layout';

import {
  paid,
  failed,
  canceled,
  expired,
  open,
  unknown,
} from '../utils/paymentResultMessages';
import { getJWTToken } from '../utils/jwt';

const PaymentResult = props => {
  const [allPaymentStatusses, setAllPaymentStatusses] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the URl parameters
    const urlParameter = props.location.search;
    // Get the part after the = sign, which is the actual orderId
    const orderId = urlParameter.split("=")[1];

    const orderPromise = new Promise((resolve, reject) =>
      fetch(`api/order/orderID/${orderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getJWTToken()}`
        }
      }).then(response => {
        if (!response.ok) {
          setError(null);
          throw response;
        }
        return response.json();
      })
        .then(order => resolve(order.paymentStatus))
        .catch(() => reject("Er is een error opgetreden bij het ophalen van de betalingsstatus")));

    const paymentStatussesPromise = new Promise((resolve, reject) =>
      fetch(`api/mollie/payment-statusses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getJWTToken()}`
        }
      })
        .then(response => {
          if (!response.ok) {
            setError(null);
            throw response;
          }
          return response.json();
        })
        .then(paymentStatusses => resolve(paymentStatusses))
        .catch(() => reject("Er is een error opgetreden bij het ophalen van de betalingsstatussen"))
    )

    Promise.all([orderPromise, paymentStatussesPromise])
      .then(values => {
        setPaymentStatus(values[0]);
        setAllPaymentStatusses(values[1]);
      })
      .catch(error => setError(error));
  }, [props.location.search, paymentStatus])

  const renderPage = () => {
    if (!paymentStatus || !allPaymentStatusses) return <Loader type="ThreeDots" color="#8b8f65" height={50} width={50} />;

    const currentPaymentStatus = allPaymentStatusses.find(element => element.value === paymentStatus);
    switch (currentPaymentStatus.name.toLowerCase()) {
      case "paid":
        return paid;
      case "failed":
        return failed;
      case "canceled":
        return canceled;
      case "expired":
        return expired;
      case "unknow":
        return unknown;
      default:
        return open;
    }
  };

  return (
    <Layout>
      <section className="section-center-text">
        <div className="container">
          <div className="page-content">
            {error != null ? (
              <div className="alert alert-danger mt-3" role="alert">
                {error.status ? (
                  <>
                    Er is helaas een error opgetreden met de code {error.status}.
                    <br />
              Neem contact op met de beheerder en/of probeer het later nog eens.
                  </>
                ) : (
                    <>{error}</>
                  )}
              </div>
            ) : renderPage()}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PaymentResult;