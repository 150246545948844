import React, { useState } from 'react';

import Layout from '../components/layout';
import PlotPicker from '../components/PlotPicker/PlotPicker';
//import { DetermineSpotPrice } from '../constants/PriceService';
import Hero from '../images/SpoorparkHero.jpg';

const PlotPickerPage = ({ history }) => {
  // New React Hooks :)
  const [error] = useState();
  const [selectedPlots, setSelectedPlots] = useState([]);
  const [timeframe, setTimeframe] = useState('1 jaar');

  const determinePricePerSpot = () => {
    switch (timeframe) {
      case '3 jaar':
        // 3 years costs €60 instead of €75, which saves €5 per year
        return '66';
      case '5 jaar':
        // 5 years costs €90 instead of €125, which saves €7 per year
        return '100';
      default:
        return '25';
    }
  };

  const confirmPlots = () => {
    const plotIds = selectedPlots.map(plot => plot.properties.guid);
    localStorage.setItem('plotIds', JSON.stringify(plotIds));
    history.push({
      pathname: '/user-information',
      state: { plotIds, timeframe },
    });
  };

  return (
    <Layout>

      <div className="hero-vrienden-spoorpark">
        <div className="hvs-wrap">
          <div className="background-image">
            <img src={Hero} alt="Hero"></img>
          </div>
          <div className="hvs-title">
            <h1>
              Vrienden van het Spoorpark
            </h1>
          </div>
          <div className="hvs-divider"></div>
        </div>
      </div>

      <section className="section-center-text">
        <div className="container">
          <div className="page-content">
            <h1>Kies jouw spot(s)</h1>
            <p>
              Door het selecteren van een of meerdere spots op onderstaande kaart kun je een stukje Spoorpark adopteren.
              Om een beter zicht te krijgen op de verschillende plots kun je ook inzoomen op de kaart.
        <br />
              <b>Let op: Wanneer je spot(s) langer dan een jaar adopteert krijg je korting!</b>
            </p>
            <div className="spotpicker-wrap">
              <div>
                <PlotPicker spotapi='/api/spot/geojson' setSelectedPlots={setSelectedPlots} />
              </div>
              <h3>
                Legenda
                </h3>
              <ul>
                <li className="beschikbaar">
                  Beschikbaar
                    </li>
                <li className="gereserveerd">
                  Gereserveerd
                    </li>
                <li className="geadopteerd">
                  Geadopteerd
                    </li>
              </ul>
            </div>

            <div className="spotpicker-select-form">

              <h2 className="ssf-title">
                Kies de adoptietijd van de geselecteerde spot(s)
                </h2>

              <div className="ssf-wrap">
                <div className="abbo-container">
                  <input type="radio" id="abbonementKids" name="abbonement" value="1 jaar"
                    onClick={event => setTimeframe(event.target.value)}></input>
                  <label className="abbo-label" htmlFor="abbonementKids">
                    <div className="abbo-content eenjaar">
                      <span className="weken">
                        1 Jaar
                                </span>
                      <span className="abonnement">
                        € 25,- per spot per jaar
                                </span>
                    </div>
                  </label>
                </div>
                <div className="abbo-container">
                  <input type="radio" id="abbonementJeugd" name="abbonement" value="3 jaar"
                    onClick={event => setTimeframe(event.target.value)}></input>
                  <label className="abbo-label" htmlFor="abbonementJeugd">
                    <div className="abbo-content tweejaar">
                      <span className="weken">
                        3 jaar
                                </span>
                      <span className="abonnement">
                        € 22,00 per spot per jaar
                                </span>
                    </div>
                  </label>
                </div>
                <div className="abbo-container">
                  <input type="radio" id="abbonementVolwassen" name="abbonement" value="5 jaar"
                    onClick={event => setTimeframe(event.target.value)}></input>
                  <label className="abbo-label" htmlFor="abbonementVolwassen">
                    <div className="abbo-content driejaar">
                      <span className="weken">
                        5 jaar
                                </span>
                      <span className="abonnement">
                        € 20,- per spot per jaar
                                </span>
                    </div>
                  </label>
                </div>
              </div>

              <p>
                Je hebt momenteel <b>{selectedPlots.length}</b> spot(s) geselecteerd voor een totaalbedrag van{' '}
                <b> €{selectedPlots.length * determinePricePerSpot()},00</b>.
              </p>

              <button
                className="button"
                type="button"
                onClick={confirmPlots}
                disabled={selectedPlots.length === 0}
              >
                Volgende stap
                </button>

              {error && (
                <div className="alert alert-danger" style={{ marginTop: '20px' }}>
                  Kon spots niet reserveren. Probeer het later opnieuw.
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PlotPickerPage;
