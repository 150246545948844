export default timeframe => {
  switch (timeframe) {
    case '1 jaar':
      // First multiply by the amount of senconds in an hour.
      // Take a whole day after that and multiply by 365 days a year.
      return 3600 * 24 * 365;
    case '3 jaar':
      // Multiply year by 3
      return 3600 * 24 * 365 * 3;
    case '5 jaar':
      // Multiply year by 5
      return 3600 * 24 * 365 * 5;
    default:
      // Default is one year too
      return 3600 * 24 * 365;
  }
};
