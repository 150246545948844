import React from 'react';

import Layout from '../components/layout';
import PlotTable from '../components/PlotTable/PlotTable';
import CallToAction from '../components/call-to-action';

const Cart = ({
  location: {
    state: { plotIds, timeframe },
  },
}) => (
  <Layout>
    <h1>Aankoopoverzicht</h1>
    <PlotTable plotIds={plotIds} timeframe={timeframe} />
    <CallToAction
      text="Akkoord"
      route={{
        pathname: '/user-information',
        state: { plotIds, timeframe },
      }}
    />
  </Layout>
);

export default Cart;
