import React from 'react';
//import './styles.css';
import Hero from '../../../images/FooterBackground.png';

const Footer = () => (
  <div>
  <div className="image-spoorpark-vrienden">
      <img src={Hero} alt="img alt"></img>
    <div className="isv-divider"></div>
  </div>
  <footer className="footer-spoorpark-vrienden">
    <div className="container">
      <div className="fsv-wrap">
          <span className="fsv-link">
            Vrienden van Spoorpark Tilburg | Ontwikkeling: <a target="_blank" rel="noreferrer" href="https://heabb.nl/" style={{ color: "white" }}>Heabb</a> & <a target="_blank" rel="noreferrer" href="https://swooth.nl/" style={{ color: "white" }}>Swooth</a>
            </span>
        <div className="fsv-social">
            <a target="_blank" rel="noreferrer" href="http://facebook.com/spoorparktilburg">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/spoorparktilburg">
            <i className="fab fa-instagram"></i>
          </a>
            <a target="_blank" rel="noreferrer" href="http://twitter.com/spoorpark013">
            <i className="fab fa-twitter"></i>
          </a>
            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC1Yd_OcT8XtiLLwXuF84mjg">
            <i className="fab fa-youtube"></i>
          </a>
            <a target="_blank" rel="noreferrer" href="https://www.flickr.com/photos/spoorparktilburg">
            <i className="fab fa-flickr"></i>
          </a>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/spoorpark-tilburg/">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </div>
    </footer>
  </div>
);

export default Footer;
