// Merges all the passed in schema's together.
// It takes an infinite amount of schemas as they're all spread out
export default (...schemas) => {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );

  return merged;
};
