import React from 'react';
import CallToAction from '../components/call-to-action';
import Layout from '../components/layout';
import UserDetails from '../components/User/UserDetails';
import OrderDetails from '../components/User/OrderDetails';

const User = ( { history }) => {
  
const Navigate = (path) => {
  history.push({pathname: path})
}
return (
  <Layout>
    <h1 className="jumbotron">Mijn account</h1>
    <section className="section-center-text">
      <div className="container">
        <div className="page-content">

          <div className="gebruiker-overzicht-wrap">
            <div className="gow-tile-wrap">
            <a onClick={() => Navigate('/gebruiker/instellingen')}>
              <div className="gow-tile">
                <div className="gow-icon">
                  <i className="far fa-user-circle"></i>
                </div>
                <div className="gow-title">
                      Accountgegevens
                </div>
              </div>
              </a>
            </div>
            <div className="gow-tile-wrap">
              <a onClick={() => Navigate("/gebruiker/orderoverzicht")}>
              <div className="gow-tile">
                <div className="gow-icon">
                  <i className="far fa-file-alt"></i>
                </div>
                <div className="gow-title">
                  Mijn spots
                </div>
              </div>
                </a>
              </div>
            </div>
          </div>

        </div>
    </section>
  </Layout>
);
}

const UserSettings = ({ history }) => (
  <Layout>
    <h1 className="jumbotron">Accountgegevens</h1>
    <section className="section-center-text">
      <div className="container">
        <div className="page-content">
          <UserDetails history={history} />
          <CallToAction text="Terug" route="/gebruiker" />
        </div>
      </div>
    </section>
  </Layout>
);

const UserOrder = ({ history }) => (
  <Layout>
    <h1 className="jumbotron">Mijn spots</h1>
    <section className="section-center-text">
      <div className="container">
        <div className="page-content">
          <OrderDetails history={history} />
          <CallToAction text="Terug" route="/gebruiker" />
        </div>
      </div>
    </section>
  </Layout>
);

export { User, UserSettings, UserOrder };