import React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

class UserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.getUserByUsername = this.getUserByUsername.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);

    this.state = {
      error: '',
      user: null,
      edit: false,
      name: '',
      email: '',
      phoneNumber: '',
      dateOfBirth: '',
    };
  }

  componentWillMount() {
    this.getUserByUsername(localStorage.getItem('username'));
  }

  getUserByUsername(username) {
    fetch(`/api/account/username/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          user: res,
          name: res.Name,
          email: res.Email,
          phoneNumber: res.PhoneNumber,
          dateOfBirth: new Date(res.dateOfBirth * 1000)
          //dateOfBirth: new Date()
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  async handleSubmit(event) {
    event.preventDefault();
    const updatedUser = this.state.user;
    updatedUser.name = this.state.name;
    updatedUser.phoneNumber = this.state.phoneNumber;
    updatedUser.dateOfBirth = this.state.dateOfBirth;
    //updatedUser.dateOfBirth = Math.round(
    //  this.state.dateOfBirth.getTime() / 1000
    ;
    delete updatedUser.links;
    delete updatedUser.address;
    delete updatedUser.orders;
    await fetch(`/api/account`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
      body: JSON.stringify(updatedUser),
    })
      .then(response => response.text())
      .catch(error => {
        this.setState({ error });
      });
    this.setState({ edit: false });
    this.getUserByUsername(localStorage.getItem('username'));
  }

  handleDateChange(event) {
    this.setState({ dateOfBirth: new Date(event.target.value) });
  }

  deleteAccount() {
    const confirm = window.confirm(
      'Weet u zeker dat u uw account wilt verwijderen?'
    );
    if (confirm) {
      fetch(`/api/account/${localStorage.getItem('accountID')}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
        },
      })
        .then(response => {
          if (response.status !== 204) {
            throw response;
          }
          localStorage.removeItem('jwt-token');
          localStorage.removeItem('accountID');
          localStorage.removeItem('username');
          this.props.history.push('/login');
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      // Do nothing!
    }
  }

  render() {
    if (localStorage.getItem('jwt-token') != null && this.state.user != null) {
      //const inputDateOfBirth = `${this.state.dateOfBirth.getFullYear()}-${`0${this.state.dateOfBirth.getMonth() + 1}`.slice(-2)}-${`0${this.state.dateOfBirth.getDate()}`.slice(-2)}`;
      if (!this.state.edit) {
        return (
          <div className="container">
            <table cellPadding="10" className="TableNoBorder">
              <tbody>
                <tr>
                  <td>Naam:</td>
                  <td>{this.state.user.name}</td>
                </tr>
                <tr>
                  <td>Gebruikersnaam:</td>
                  <td>{this.state.user.username}</td>
                </tr>
                <tr>
                  <td>Geboortedatum:</td>
                  <td>{this.state.dateOfBirth.toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{this.state.user.email}</td>
                </tr>
                <tr>
                  <td>Telefoonnummer:</td>
                  <td>{this.state.user.phoneNumber}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <div
              className="d-flex icon-wrapper"
              style={{ margin: '0px 50px 0px 0px', float: 'left' }}
              onClick={() => this.setState({ edit: true })}
            >
            </div>
            <div
              className="d-flex icon-wrapper"
              style={{ marginLeft: '-100px', float: 'left' }}
              onClick={() => this.deleteAccount()}
            >
            </div>
            <br />
            <br />
            {this.state.error && (
              <div className="alert alert-danger mt-3" role="alert">
                Er is helaas een error opgetreden met de code {this.state.rror}.{' '}
                <br />
                Neem contact op met de beheerder en/of probeer het later nog
                eens.
              </div>
            )}
          </div>
        );
      }
      return (
        <div className="container">
          <form autoComplete="off">
            <table cellPadding="10">
              <tbody>
                <tr>
                  <td>Naam:</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.name}
                      onChange={this.handleChange}
                      id="name"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Gebruikersnaam:</td>
                  <td>{this.state.user.username}</td>
                </tr>
                <tr>
                  <td>Geboortedatum:</td>
                  <td>
                    <input
                      type="date"
                      //value={inputDateOfBirth}
                      value={this.state.dateOfBirth.toLocaleDateString()}
                      onChange={this.handleDateChange}
                      id="dateOfBirth"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{this.state.email} </td>
                </tr>
                <tr>
                  <td>Telefoonnummer:</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.phoneNumber}
                      onChange={this.handleChange}
                      id="phoneNumber"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              type="button"
              className="btn btn-info btn-md active"
              onClick={this.handleSubmit}
            >
              Opslaan
            </button>
          </form>
          <br />
          {this.state.error && (
            <div className="alert alert-danger mt-3" role="alert">
              Er is helaas een error opgetreden met de code {this.state.error}.{' '}
              <br />
              Neem contact op met de beheerder en/of probeer het later nog eens.
            </div>
          )}
        </div>
      );
    }
    return (
      <div>
        Laden <br /> <br />
      </div>
    );
  }
}

export default UserDetails;
