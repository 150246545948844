import React from 'react';

export const paid = (
  <>
    <h1>Beste vriend van het Spoorpark,</h1>
    <p>
      Bedankt voor het adopteren van jouw eigen spot in het Spoorpark! <br />
      Dankzij jouw steun help je ons in het beheer en onderhoud van het park.
    </p>
    <p>
      Om de details van je adoptie te bekijken, inclusief de coördinaten, kun je inloggen met je E-mailadres en wachtwoord. Daar kun je ook een officieel certificaat downloaden als bewijs van onze vriendschap. Leuk om te printen en in te lijsten.
    </p>
    <p>
      Graag tot ziens in het park!
    </p>
  </>
);

export const failed = (
  <>
    <h1>Betaling mislukt</h1>
    <p>
      Helaas ging er iets mis bij het betalen... <br />
      Probeer het nog eens of neem contact op met het Spoorpark.
    </p>
  </>
);

export const canceled = (
  <>
    <h1>Betaling geannuleerd</h1>
    <p>Jammer dat u ervoor heeft gekozen om de betaling te annuleren.</p>
  </>
);

export const expired = (
  <>
    <h1>Betaling verlopen</h1>
    <p>
      Helaas heeft u de betaling te lang uitgesteld. <br />
      Voor de veiligheid is de betaling als verlopen gemarkeerd. <br />
      Gelieve het bestellen opnieuw te proberen.
    </p>
  </>
);

export const unknown = (
  <>
    <h1>Onbekende status</h1>
    <p>
      Waarschijnlijk is uw betaling nog in behandeling. Zodra u een emailbevestiging ontvangt bent u verzekerd van uw spot in het Spoorpark! <br />
      Neem contact op met het Spoorpark wanneer u voor lange tijd geen mail ontvangt.
    </p>
  </>
);

export const open = (
  <>
    <h1>Onbekende status</h1>
    <p>
      Waarschijnlijk is uw betaling nog in behandeling. Zodra u een emailbevestiging ontvangt bent u verzekerd van uw spot in het Spoorpark! <br />
      Neem contact op met het Spoorpark wanneer u voor lange tijd geen mail ontvangt.
    </p>
  </>
);
