import React from 'react';
import { Field, ErrorMessage } from 'formik';

//import './styles.css';

const PresentInfo = () => (
  <div className="form-row">
    <div className="col">
      <div className="form-group">
        <label htmlFor="receiverName">Naam ontvanger</label>
        <Field
          type="text"
          id="receiverName"
          name="receiverName"
          className="form-control"
        />
        <ErrorMessage
          className="alert alert-danger"
          name="receiverName"
          component="div"
        />
      </div>
    </div>
    <div className="col">
      <div className="form-group">
        <label htmlFor="receiverEmail">Email ontvanger</label>
        <Field
          type="text"
          id="receiverEmail"
          name="receiverEmail"
          className="form-control"
        />
        <ErrorMessage
          className="alert alert-danger"
          name="receiverEmail"
          component="div"
        />
      </div>
    </div>
  </div>
);

export default PresentInfo;
