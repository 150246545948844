import * as Yup from 'yup';

export default Yup.object().shape({
  firstName: Yup.string().required('Voornaam is verplicht'),
  lastName: Yup.string().required('Achternaam is verplicht'),
  phoneNumber: Yup.string().required('Telefoonnummer is verplicht'),
  email: Yup.string()
    .email('Het ingevulde e-mailadres is niet valide')
    .required('Email is verplicht'),
  birthdate: Yup.string('De ingevulde datum is niet valide')
    .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/, {
      message: 'De ingevulde datum moet voldoen aan het dd/mm/jjjj formaat',
      excludeEmptyString: true,
    })
    .required('Geboortedatum is verplicht'),
  street: Yup.string().required('Straatnaam is verplicht'),
  number: Yup.number()
    .positive('Huisnummer moet positief zijn')
    .required('Huisnummer is verplicht'),
  addition: Yup.string(),
  residency: Yup.string().required('Woonplaats is verplicht'),
  postalCode: Yup.string().required('Postcode is verplicht'),
});
