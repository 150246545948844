import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { ExportToCsv } from 'export-to-csv';
import Loader from 'react-loader-spinner';

import SearchField from '../search-field';
import { getJWTToken } from '../../utils/jwt';

const AccountList = () => {
  const [error, setError] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [isFiltering, setFiltering] = useState(false);
  const [accountCount, setAccountCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const [csvOptions, setCsvOptions] = useState();

  useEffect(() => {
    getAccounts(1, 5);
    getAllAccounts().then(fetchedAccounts => {
      setAllAccounts(fetchedAccounts.accounts);

      let newCsvData = [];
      fetchedAccounts.accounts.forEach(account => {
        if (
          !account.email.includes('anonymous') &&
          !account.email.includes('admin')
        ) {
          newCsvData = newCsvData.concat({
            'Vrienden van Spoorpark emailadressen': account.email,
          });
        }
      });

      setCsvData(newCsvData);

      const date = unixTimeToDate(Date.now());
      const options = {
        fieldSeparator: ',',
        filename: `Vrienden_van_Spoorpark_emailadressen_${date}`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
      setCsvOptions(options);
    });
  }, []);

  const getAccounts = (Page, Size) =>
    fetch(`api/account?Page=${Page - 1}&Size=${Size}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getJWTToken()}`,
        },
      }
    )
      .then(result => result.json())
      .then(result => {
        setAccounts(result.accounts);
        setAccountCount(result.count);
        setFilteredAccounts(result.accounts);
      })
      .then(() => setLoading(false))
      .catch(error => setError(error));

  const getAllAccounts = () =>
    fetch(`api/account/GetAll`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
    })
      .then(result => result.json())
      .catch(error => setError(error));

  const handleDelete = account => {
    const deletionConfirmed = window.confirm(
      `Are you sure you want to delete account: ${account.name}?`
    );

    if (deletionConfirmed) {
      fetch(`api/account/${account.id} `, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
        },
      })
        .then(result => {
          if (!result.ok) {
            result.text().then(error => setError(error));
          }
          window.location.reload();
        })
        .catch(error => setError(error));
    }
  };

  const handlePageChange = pageNumber => {
    getAccounts(pageNumber, 5);
    setActivePage(pageNumber);
  };

  const unixTimeToDate = unixTimeStamp => {
    const date = new Date(unixTimeStamp * 1000);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  const search = value => {
    const locallyFilteredAccounts = [];
    if (value.length > 0) {
      allAccounts.forEach(account => {
        if (account.name.toLowerCase().includes(value.toLowerCase()))
          locallyFilteredAccounts.push(account);
      });
      setFilteredAccounts(locallyFilteredAccounts);
      setFiltering(true);
    } else {
      setFilteredAccounts(accounts);
      setFiltering(false);
    }
  };

  if (error)
    return (
      <div className="alert alert-danger mt-3" role="alert">
        {error.status ? (
          <>
            Er is helaas een error opgetreden met de code {error.status}.
            <br />
            Neem contact op met de beheerder en/of probeer het later nog eens.
          </>
        ) : (
            <>{error}</>
          )}
      </div>
    );
  if (isLoading)
    return (
      <center>
        <Loader type="ThreeDots" color="#8b8f65" height={50} width={50} />
      </center>
    );
  return (
    <div className="container">
      <SearchField
        placeholder="Zoek op naam"
        onEnter={search}
        onSearchClick={search}
      />

      <button
        type="button"
        className="button"
        onClick={() => new ExportToCsv(csvOptions).generateCsv(csvData)}
        style={{ marginLeft: '15px' }}
      >
        Download emailadressen
      </button>

      <ul className="list-group" style={{ marginTop: '15px' }}>
        {filteredAccounts.length > 0 ? (
          filteredAccounts.map(account => {
            if (
              !account.name.startsWith('anonymous') &&
              account.username !== localStorage.getItem('username')
            )
              return (
                <li className="list-group-item" key={account.id}>
                  <p>Naam: {account.name}</p>
                  <p>Gebruikersnaam: {account.username}</p>
                  <p>Emailadres: {account.email}</p>
                  <p>Orders: {account.orders.length}</p>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm active"
                    onClick={() => handleDelete(account)}
                  >
                    Verwijder
                  </button>
                </li>
              );
            return null;
          })
        ) : (
            // Match the width of the unfiltered account list
            <li className="list-group-item" style={{ width: '618px' }}>
              Geen accounts gevonden
            </li>
          )}
      </ul>
      <div className="row justify-content-center" style={{ marginTop: 20 }}>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={5}
          totalItemsCount={isFiltering ? filteredAccounts.length : accountCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
};

export default AccountList;
