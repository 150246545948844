import React/*, { useContext } */from 'react';
import jwt_decode from 'jwt-decode';

import { AuthenticationContext } from '../../utils/AuthenticationContext';
import { addJWTToken } from '../../utils/jwt';
//import './styles.css';


class LoginForm extends React.Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: null,
    };
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = event => {
    const { setAuthenticated } = this.context;
    event.preventDefault();
    fetch('/api/authentication', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      }),
    })
      .then(response => {
        if (response.ok) return response.text();
        throw response;
      })
      .then(token => addJWTToken(token))
      .then(() => setAuthenticated(true))
      .then(() => {
        const decoded = jwt_decode(localStorage.getItem('jwt-token'));
        localStorage.setItem('username', decoded.username);
        localStorage.setItem('accountID', decoded.accountID);
        if (decoded.role === 'Admin') {
          this.props.history.push('/admin/overzicht');
        } else {
          const error = new Error('Incorrecte inloggegevens');
          error.status = 401;
          throw error;
        }
      })
      .catch(error => this.setState({ error }));
  };

  showErrorMessage = error => (
    <div className="alert alert-danger">
      {error.status === 401
        ? 'Incorrecte inloggegevens'
        : `Een error met de code ${error.status} is opgetreden.
          Neem contact op met de administrator en/of probeer het opnieuw.`}
    </div>
  );

  render() {
    return (
      <>
        {this.state.error && this.showErrorMessage(this.state.error)}
        <form autoComplete="off" style={{ width: '300px' }}>
          <div className="form-group">
            <label htmlFor="username">E-mailadres</label>
            <input
              type="text"
              id="username"
              placeholder="E-mailadres"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Wachtwoord</label>
            <input
              type="password"
              id="password"
              placeholder="Wachtwoord"
              value={this.state.password}
              onChange={this.handleChange}
            />
          </div>
          <button
            type="submit"
            className="button"
            onClick={this.handleSubmit}
          >
            Login
          </button>
        </form>
      </>
    );
  }
}

export default LoginForm;
