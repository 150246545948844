import React from 'react';

class AccountCreate extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          name: '',
          username: '',
          password: '',
          email: '',
    };
  }

  validateForm() {
      return this.state.username.length > 0
          && this.state.password.length > 0
          && this.state.name.length > 0
          && this.state.email.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    fetch('/api/account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
        body: JSON.stringify({
            name: this.state.name,
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
      }),
    })
      .then(response => {
        // A new account has been added, reload.
        window.location.reload();
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <form autoComplete="off" style={{ width: '250px' }}>
        <div className="form-group">
          <p>Name</p>
          <input
            type="text"
            className="form-control"
            id="name"
            value={this.state.name}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group">
          <p>Username</p>
          <input
            type="text"
            className="form-control"
            id="username"
            value={this.state.username}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group">
          <p>Password</p>
          <input
            type="password"
            className="form-control"
            id="password"
            value={this.state.password}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group">
          <p>email</p>
          <input
            type="text"
            className="form-control"
            id="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </div>
        <button
          type="button"
          className="button"
          onClick={this.handleSubmit}
        >
          Create Account
        </button>
      </form>
    );
  }
}

export default AccountCreate;
