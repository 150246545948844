import React, { useState } from 'react';
import CallToAction from '../components/call-to-action';

import Layout from '../components/layout';
import AccountList from '../components/Admin/AccountList';
import AccountCreate from '../components/Admin/AccountCreate';
import OrderList from '../components/Admin/OrderList';
import LoginForm from '../components/Admin/LoginForm';
//import SpotList from '../components/Admin/SpotList';
//import SpotCreate from '../components/Admin/SpotCreate';
import PlotPicker from '../components/PlotPicker/PlotPicker';

const Admin = () => (
  <Layout>
    <h1 className="jumbotron">Administrator overzicht.</h1>
    <section className="section-center-text">
      <div className="container">
        <div className="page-content">
          <p>Klik hier voor een overzicht van een account.</p>
          <CallToAction text="Account Overzicht" route="/admin/overzicht/account" />
          <hr />
          <p>Klik hier voor een overzicht van plekken op de kaart.</p>
          <CallToAction text="Plekken Overzicht" route="/admin/overzicht/plek" />
          <hr />
          <p>Klik hier voor een overzicht van orders die zijn gemaakt.</p>
          <CallToAction text="Order Overzicht" route="/admin/overzicht/order" />
        </div>
      </div>
    </section>
  </Layout>
);

const AdminAccount = ({ history }) => (
  <Layout>
    <section className="section-center-text">
      <div className="container">
        <div className="page-content">
          <div className="row">
            <div className="col-md-4">
              <AccountCreate history={history} />
            </div>
            <div className="col-md-8">
              <AccountList />
            </div>
          </div>
          <hr />
          <CallToAction text="Terug" route="/admin/overzicht" />
        </div>
      </div>
    </section>
  </Layout>
);

const AdminSpot = () => {
  // Update spots open/closed/
  const confirmUpdatePlots = (plots, status) => {
    const spotIds = [];
    for (const spot of plots) {
      spotIds.push(spot.properties.guid);
    }

    fetch(`/api/spot/updateSpots/${status}`, {
      method: 'PUT',
      body: JSON.stringify(spotIds),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
    })
      .then(() => window.location.reload())
      .catch(error => {
        this.setState({ error });
      });
  };

  const [selectedPlots, setSelectedPlots] = useState([]);
  return (
    <Layout>
      <section className="section-center-text">
        <div className="container">
          <div className="page-content">
            <div className="container">
              <PlotPicker
                spotapi="/api/spot/geojsonadmin"
                setSelectedPlots={setSelectedPlots}
              />
              <br />
              <button
                className="button"
                type="button"
                onClick={() => confirmUpdatePlots(selectedPlots, 0)}
                disabled={selectedPlots.length === 0}
              >
                Open geselecteerde spots
        </button>{' '}
              <button
                className="button"
                type="button"
                onClick={() => confirmUpdatePlots(selectedPlots, 2)}
                disabled={selectedPlots.length === 0}
              >
                Sluit geselecteerde spots
      </button>

            </div>
            <br />
            <hr />
            <CallToAction text="Terug" route="/admin/overzicht" />
          </div>
        </div>
      </section>
    </Layout>
  );
};

const AdminOrder = () => (
  <Layout>
    <section className="section-center-text">
      <div className="container">
        <div className="page-content">
          <OrderList />
          <hr />
          <CallToAction text="Terug" route="/admin/overzicht" />
        </div>
      </div>
    </section>
  </Layout>
);

const AdminLogin = ({ history }) => (
  <Layout>
    <section className="checkout-page-section">
      <div className="container">
        <div className="cps-wrap">
          <div className="form">
            <h1>Admin login</h1>
            <LoginForm history={history} />
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export { Admin, AdminAccount, AdminSpot, AdminOrder, AdminLogin };
