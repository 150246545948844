import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction = ({ text, route}) => (
  <Link to={route} className="button">
    {text}
  </Link>
);

export default CallToAction;
