import timestringToUnix from './TimestringToUnix';
import timeframeToUnix from './TimeframeToUnix';
import { getJWTToken } from './jwt';

export const prepareAccountObject = (
  {
    username,
    password,
    firstName,
    lastName,
    phoneNumber,
    email,
    birthdate,
    street,
    number,
    addition,
    residency,
    postalCode,
    receiverName,
  },
  isReceiverAccount = false
) => ({
  username,
  password,
  email,
  role: 1,
  name: isReceiverAccount ? receiverName : `${firstName} ${lastName}`,
  phoneNumber: isReceiverAccount ? '' : phoneNumber,
  dateOfBirth: isReceiverAccount ? 0 : timestringToUnix(birthdate),
  address: isReceiverAccount
    ? null
    : {
        street,
        number,
        addition,
        residency,
        postalCode,
      },
});

export const persistAccount = account =>
  new Promise((resolve, reject) =>
    fetch('/api/account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(account),
    }).then(response => {
      if (!response.ok) reject(response);
      else resolve(response);
    })
  );

export const reserveSpots = spotIds =>
  new Promise((resolve, reject) =>
    fetch('/api/spot/reserve', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getJWTToken()}`,
      },
      body: JSON.stringify(spotIds),
    }).then(response => {
      if (!response.ok) reject(response);
      else resolve(response);
    })
  );

export const persistOrder = (plotIds, timeframe, receiverId) => {
  const unixDifference = timeframeToUnix(timeframe);

  // TODO: remove available and price parameters from being parsed in the backend
  const order = {
    purchaseDate: Date.now() / 1000,
    expirationDate: Date.now() / 1000 + unixDifference,
    spots: plotIds.map(id => ({
      id,
    })),
  };

  let orderUrl;
  if (receiverId) orderUrl = `/api/order/${localStorage.getItem('accountID')}/${receiverId}`;
  else orderUrl = `/api/order/${localStorage.getItem('accountID')}`;

  return new Promise((resolve, reject) =>
    fetch(orderUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getJWTToken()}`,
      },
      body: JSON.stringify(order),
    }).then(response => {
      if (!response.ok) reject(response);
      else resolve(response.text());
    })
  );
};
