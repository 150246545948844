export default {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  birthdate: '',
  street: '',
  number: '',
  addition: '',
  residency: '',
  postalCode: '',
  username: '',
  password: '',
  receiverName: '',
  receiverEmail: '',
};
