const generateRandomPassword = () => {
  const possibleCharacters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = possibleCharacters.length;

  let generatedPassword = '';
  for (let i = 0; i < 20; i += 1) {
    generatedPassword += possibleCharacters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }

  return generatedPassword;
};

export default generateRandomPassword;
