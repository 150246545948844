export const createPayment = async (orderid, receiveremail) =>
  new Promise(reject =>
    fetch(`api/mollie/idealPay/${orderid}/${receiveremail}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
    })
      .then(response => {
        if (!response.ok) reject(response);
        return response.json();
      })
      .then(json => {
        localStorage.setItem('paymentID', json.id);
        window.location = json.url;
      })
  );
